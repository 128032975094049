import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

export const useTermsPageContent = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { wpPageEn, wpPageRo } = useStaticQuery(graphql`
    query {
      wpPageRo: wpPage(
        template: { templateName: { eq: "Page Terms" } }
        language: { code: { eq: RO } }
      ) {
        termsPageFields {
          pageTitle
          pageContent
        }
      }
      wpPageEn: wpPage(
        template: { templateName: { eq: "Page Terms" } }
        language: { code: { eq: EN } }
      ) {
        termsPageFields {
          pageTitle
          pageContent
        }
      }
    }
  `)

  const pages = {
    en: wpPageEn,
    ro: wpPageRo,
  }

  return pages[language]?.termsPageFields || wpPageRo?.termsPageFields
}
