import React from "react"
import { graphql } from "gatsby"
import { useTermsPageContent } from "../hooks/TermsPage/useTermsPageContent"
import Layout from "../components/Layout/Layout"
import Header from "../components/Header/Header"
import PageContentSection from "../components/PageContentSection/PageContentSection"
import Footer from "../components/Footer/Footer"

const TermsPage = () => {
  const { pageTitle, pageContent } = useTermsPageContent()
  return (
    <Layout>
      <Header />
      <PageContentSection title={pageTitle} pageContent={pageContent} />
      <Footer />
    </Layout>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default TermsPage
